<div class="data-export-container">
  <button class="back-btn" (click)="goBack()">
    <i class="fas fa-arrow-left"></i> Back to Dashboard
  </button>

  <h1>Export Template Data</h1>

  <p class="description">
    Select the Templates and Date Range to export data for. The data will be downloaded as a CSV | XLSX file.
  </p>

  <div class="form-card">
    <h2>Select Clinical Modules</h2>
    <ng-select [items]="clinicalModules" bindLabel="name" bindValue="value" placeholder="Select / Search Clinical Module ..."
      [multiple]="false" [searchable]="true" [(ngModel)]="selectedClinicalModule" (change)="loadTemplates()">
    </ng-select>
  
    <div *ngIf="!clinicalModules.length" class="no-data">
      <p>No clinical Modules Selected. Kindly select the clinical Modules for which you would like to Export Data for.</p>
    </div>
  </div>
  
  <div class="form-card">
    <h2>Select Templates</h2>
    <ng-select [items]="templates" bindLabel="name" bindValue="caseId" placeholder="Select / Search Templates ..."
      [multiple]="false" [searchable]="true" [(ngModel)]="selectedCaseId">
    </ng-select>
  
    <div *ngIf="!templates.length" class="no-data">
      <p>No Templates Selected. Kindly select the Templates for which you would like to Export Data for.</p>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>

  
  <div class="form-card">
    <h2>Select Date Range</h2>
    <div class="date-range">
      <div class="date-input">
        <label for="fromDate">From</label>
        <input type="date" id="fromDate" [(ngModel)]="fromDate" />
      </div>
      <div class="date-input">
        <label for="toDate">To</label>
        <input type="date" id="toDate" [(ngModel)]="toDate" />
      </div>
    </div>
  </div>

  <button class="export-btn request-btn" (click)="exportData()" *ngIf="!data.length">
    <i class="fas fa-file-download"></i> Request Data
  </button>

  <div *ngIf="!data.length" class="no-data">
    <p>No data available.
      Kindly make a Valid Request to access Data.
    </p>
  </div>

  <div *ngIf="data.length" class="data-preview">
    <div class="button-group">
      <div class="download-buttons">
        <button class="export-btn download-btn" (click)="downloadData('csv')">
          <i class="fas fa-file-download"></i> Download CSV
        </button>
        <button class="export-btn download-btn" (click)="downloadData('xlsx')">
          <i class="fas fa-file-download"></i> Download XLSX
        </button>
      </div>
      <div class="preview-clear-buttons">
        <button class="export-btn preview-btn" (click)="previewData()">
          <i class="fas fa-eye"></i> Preview Data
        </button>
        <button class="export-btn clear-btn" (click)="clearData()">
          <i class="fas fa-trash"></i> Clear Data <i class="fas fa-trash"></i> 
        </button>
      </div>
    </div>
  </div>
</div>
