import { Subscription } from "rxjs";
import { AdministrativeReportsRoutingModule } from "./components/reports/administrative-reports/administrative-reports-routing.module";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AdminComponent } from "./theme/layout/admin/admin.component";
import { AuthComponent } from "./theme/layout/auth/auth.component";
import { UserComponent } from "./theme/layout/user/user.component";
import { AuthGuard } from "src/app/others/guards/auth.guard";
// import { LandingPageComponent } from "./components/landing-page/landing-page.component";
/* import { SubGuard } from './others/guards/subscription/subscription.guard'; */
import { PathologyGuard } from "./others/guards/subscription/pathology.guard";
import { GeneralPraticeGuard } from "./others/guards/subscription/general-pratice.guard";
import { GpPathologyGuard } from "./others/guards/subscription/gp-pathology.guard";
import { Injectable } from "@angular/core";
import { SubGuard } from "./others/guards/subscription.guard";
import { TermsOfUsePageComponent } from "./terms-of-use-page/terms-of-use-page.component";
import { PrivacyPolicyPageComponent } from './privacy-policy-page/privacy-policy-page.component';
import { KeyMetricsComponent } from "./components/key-metrics/key-metrics.component";
import { TatComponent } from "./components/key-metrics/tat/tat.component";
import { ReceivablesComponent } from "./components/key-metrics/receivables/receivables.component";
import { PerformanceComponent } from "./components/key-metrics/performance/performance.component";
import { StaffCollectionComponent } from "./components/key-metrics/staff-collection/staff-collection.component";
import { DiscountsComponent } from "./components/key-metrics/discounts/discounts.component";
import { ClientReportsComponent } from "./components/key-metrics/client-reports/client-reports.component";
// import { AdminDashboardAuthGuard } from "./components/dashboards/admin-dashboard/admin-dashboard-auth.guard";

// const dashes = sessionStorage.getItem("role")
  // ?  sessionStorage.getItem("admin") === "true"
  //   ? "/dashboard/admin"
  //   : "/dashboard/staff"
  // : "*";
const routes: Routes = [
  {
    path: "",
    redirectTo: "*",
    pathMatch: "full",
  },
  {
    path: "",
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "dashboard",
        canActivate: [SubGuard],
        loadChildren: () =>
          import("./components/dashboards/dashboard.module").then(
            (module) => module.DashboardModule
          ),
      },
      {
        path: "patient",
        canActivate: [SubGuard],

        loadChildren: () =>
          import("./components/patient/patient.module").then(
            (module) => module.PatientModule
          ),
      },


      
      {
        path: "clinical-modules/general-practise",
        // canActivate: [SubGuard, GeneralPraticeGuard],
        // canActivateChild: [GeneralPraticeGuard],
        // canLoad: [GeneralPraticeGuard],
        loadChildren: () =>
          import("./components/general-practice/general-practice.module").then(
            (module) => module.GeneralPracticeModule
          ),
      },
      {
        path: "clinical-modules/pathology",
        // canActivate: [SubGuard, PathologyGuard],
        // canActivateChild: [PathologyGuard],
        // canLoad: [PathologyGuard],
        loadChildren: () =>
          import("./components/pathology/pathology.module").then(
            (module) => module.PathologyModule
          ),
      },
      {
        path: "clinical-modules/radiology",
        // canActivate: [SubGuard, PathologyGuard],
        // canActivateChild: [PathologyGuard],
        // canLoad: [PathologyGuard],
        loadChildren: () =>
          import("./components/radiology/radiology.module").then(
            (module) => module.RadiologyModule
          ),
      },
      {
        path: "clinical-modules/clinical-laboratories",
        loadChildren: () =>
          import(
            "./components/clinical-laboratories/clinical-laboratories.module"
          ).then((module) => module.ClinicalLaboratoriesModule),
      },
      {
        path: "calendar",
        canActivate: [SubGuard],

        loadChildren: () =>
          import("./components/calendar/calendar.module").then(
            (module) => module.CalendarModule
          ),
      },
      {
        path: "request",
        canActivate: [SubGuard],

        loadChildren: () =>
          import("./components/requests/requests.module").then(
            (module) => module.RequestsModule
          ),
      },
      {
        path: "stripe-pay",
        canActivate: [SubGuard],

        loadChildren: () =>
          import(
            "./components/authentication/stripe-subscription/stripe-subscription.module"
          ).then((module) => module.StripeSubscriptionModule),
      },
      {
        path: "settings",
        canActivate: [SubGuard],

        loadChildren: () =>
          import("./components/settings/settings.module").then(
            (module) => module.SettingsModule
          ),
      },
      {
        path: "stripe",
        canActivate: [SubGuard],
        loadChildren: () =>
          import("./components/stripe/stripe.module").then(
            (module) => module.StripeModule
          ),
      },
      {
        path: "billing",
        canActivate: [SubGuard],

        loadChildren: () =>
          import("./components/billing/billing.module").then(
            (module) => module.BillingModule
          ),
      },
      {
        path: "clinical-reports",
        canActivate: [SubGuard],
        loadChildren: () =>
          import(
            "./components/reports/clinical-reports/clinical-reports.module"
          ).then((module) => module.ClinicalReportsModule),
      },
      {
        path: "administrative-reports",
        canActivate: [SubGuard],

        loadChildren: () =>
          import(
            "./components/reports/administrative-reports/administrative-reports.module"
          ).then((module) => module.AdministrativeReportsModule),
      },
      {
        path: "facility-profile",

        loadChildren: () =>
          import("./components/facility-profile/facility-profile.module").then(
            (module) => module.FacilityProfileModule
          ),
      },
      //brian added for clients
      {
        path: "clients",

        loadChildren: () =>
          import("./components/clients/clients.module").then(
            (module) => module.ClientsModule
          ),
      },

      {
        path: "sms-settings",

        loadChildren: () =>
          import("./components/sms-settings/sms-settings.module").then(
            (module) => module.SmsSettingsModule
          ),
      },
      {
        path:'allergies',
        loadChildren: () => import('./components/allergies/allergies-routing.module')
        .then(m => m.AllergiesRoutingModule)
      },
      {
        path:'medications',
        loadChildren: () => import('./components/medications/medications-routing.module')
        .then(m => m.MedicationsRoutingModule)
      },
      {
        path:'vitals',
        loadChildren: () => import('./components/vitals/vitals-routing.module')
        .then(m => m.VitalsRoutingModule)
      },
      {
        path:'lab-studies',
        loadChildren: () => import('./components/lab-studies/lab-studies-routing.module')
        .then(m => m.LabStudiesRoutingModule)
      },
      {
        path:'problem-diagnosis',
        loadChildren: () => import('./components/prob-diagnosis/prob-diagnosis-routing.module')
        .then(m => m.ProbDiagnosisRoutingModule)
      },
      {
        path:'history',
        loadChildren: () => import('./components/historys/history-routing.module')
        .then(m => m.HistoryRoutingModule)
      },

      {
        path: "draft-reports",

        loadChildren: () =>
          import("./components/drafted-reports/drafted-reports.module").then(
            (module) => module.DraftedReportsModule
          ),
      },

      {
        path: "draft-complete",

        loadChildren: () =>
          import("./components/draft-completed/draft-completed.module").then(
            (module) => module.DraftCompletedModule
          ),
      },
      {
        path: "reports-review",

        loadChildren: () =>
          import("./components/reports-review/reports-review.module").then(
            (module) => module.ReportsReviewModule
          ),
      },
      // {
      //   path: "clients/requesting-doctor",

      //   loadChildren: () =>
      //     import("./components/clients/requesting-doctor/requesting-doctor.module").then(
      //       (module) => module.RequestingDoctorModule
      //     ),
      // },
      // {
      //   path: "clients/requesting-facility",

      //   loadChildren: () =>
      //     import("./components/clients/requesting-facility/requesting-facility.module").then(
      //       (module) => module.RequestingFacilityModule
      //     ),
      // },

      //end of the addition
      {
        path: "profile",

        loadChildren: () =>
          import(
            "./components/settings/auth-personal-info/auth-personal-info.module"
          ).then((module) => module.AuthPersonalInfoModule),
      },
      {
        path: "subscription",
        loadChildren: () =>
          import("./components/subscriptions/subscriptions.module").then(
            (module) => module.SubscriptionsModule
          ),
      },
      {
        path: "stripe-subscription",
        loadChildren: () =>
          import("./components/subscriptions/stripe-subscription/stripe-subscription.module").then(
            (module) => module.StripeSubscriptionModule
          ),
      },
      {
        path: "key-metrics",
        loadChildren: () =>
          import("./components/key-metrics/key-metrics.module").then(
            (m) => m.KeyMetricsModule
          ),
      },
      {
        path: "user-management",

        loadChildren: () =>
          import(
            "./components/user-management/user-management-routing.module"
          ).then((module) => module.UserManagementRoutingModule),
      },
      {
        path: "contact-support",

        loadChildren: () =>
          import("./components/support/support-routing.module").then(
            (module) => module.SupportRoutingModule
          ),
      },
      // {
      //   path: "admin-dashboard",
      //   canActivate: [SubGuard],
      //   loadChildren: () =>
      //     import("./components/admin-dashboard/admin-dashboard.module").then(
      //       (module) => module.AdminDashboardModule
      //     ),
      // },
    ],
  },
  {
    path: "",
    component: UserComponent,
    children: [
      {
        path: "user",
        loadChildren: () =>
          import("./components/user-management/user-management.module").then(
            (module) => module.UserManagementModule
          ),
      },
    ],
  },
  {
    path: "terms-of-use",
    component: TermsOfUsePageComponent,
  },
  {
    path: "privacy-policy",
    component: PrivacyPolicyPageComponent,
  },
  {
    path: "",
    component: AuthComponent,
    children: [
      {
        path: "auth",
        loadChildren: () =>
          import("./components/authentication/authentication.module").then(
            (module) => module.AuthenticationModule
          ),
      },
      {
        path: "maintenance",
        loadChildren: () =>
          import("./components/maintenance/maintenance.module").then(
            (module) => module.MaintenanceModule
          ),
      },
    ],
  },
  {
    path: "terms",
    loadChildren: () =>
      import("./components/term-of-use/term-of-use.module").then(
        (module) => module.TermOfUseModule
      ),
  },
  {
    path: "*",
    loadChildren: () =>
      import("./components/home/home.module").then(
        (module) => module.HomeModule
      ),
  },
  {
    path: "dashboard/analytics",
    loadChildren: () =>
      import("./components/home/home.module").then(
        (module) => module.HomeModule
      ),
  },
  { path: 'data-export', 
    loadChildren: () => import('./components/data-export/data-export.module').then
    (m => m.DataExportModule) },

  {
    path: "**",
    loadChildren: () =>
      import("./components/home/home.module").then(
        (module) => module.HomeModule
      ),
  },
];

@Injectable()
// export class NavigationItem {
//   dash() {
//     if (sessionStorage.getItem("role") == "Owner") {
//       return "/dashboard/admin";
//     } else if (sessionStorage.getItem("role") !== "Staff") {
//       return "/dashboard/admin";
//     } else if (sessionStorage.getItem("role") !== "Doctor") {
//       return "/dashboard/admin";
//     // } else {
//     //   return "/dashboard/staff";
//     }
//   }
// }

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
exports: [RouterModule],
})
export class AppRoutingModule {}
