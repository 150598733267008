import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/others/services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { NgxSpinnerService } from "ngx-spinner";


@Component({
  selector: 'app-data-export',
  templateUrl: './data-export.component.html',
  styleUrls: ['./data-export.component.scss']
})
export class DataExportComponent implements OnInit {

  clinicalModules = [
    { name: 'Pathology', value: 'Pathology' },
    { name: 'Radiology', value: 'Radiology' },
    { name: 'Clinical Laboratory', value: 'Clinical Laboratory' },
    { name: 'General Practice', value: 'General Practice' }
  ];

  selectedCaseId: string = '';
  selectedClinicalModule: string = '';
  fromDate: string = '';
  toDate: string = '';
  data: any[][] = [];
  headers: string[] = [];
  templates: any[] = [];

  constructor(public auth: AuthenticationService, private router: Router, private route: ActivatedRoute, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show();
    // Optionally hide spinner after loading data if needed
    setTimeout(() => { this.spinner.hide(); }, 1000);
  }

  // Load templates based on selected clinical module
  loadTemplates() {
    switch (this.selectedClinicalModule) {
      case 'Pathology':
        this.templates = [
          { name: 'Appendix Excision', caseId: 'APDX' },
          { name: 'Autopsy', caseId: 'ATSY' },
          { name: 'Breast Core and Incision Biopsies', caseId: 'BCB' },
          { name: 'Colon', caseId: 'COL' },
          { name: 'Cervical Cancer', caseId: 'CERV' },
          { name: 'Endometrial Cancer', caseId: 'ENDO' },
          { name: 'Gastrectomy', caseId: 'GAS' },
          { name: 'General Cancer Biopsy', caseId: 'GCB' },
          { name: 'General Part Biopsies', caseId: 'GB' },
          { name: 'General Template', caseId: 'GS' },
          { name: 'HPV Template', caseId: 'HPVT' },
          { name: 'Immunohistochemistry', caseId: 'IM' },
          { name: 'Immunohistochemistry Special Stains', caseId: 'IHCS' },
          { name: 'ISH Template', caseId: 'ISH' },
          { name: 'Laryngectomy', caseId: 'LRG' },
          { name: 'Mastectomy', caseId: 'MAS' },
          { name: 'Medical Liver Core Biopsy', caseId: 'MLCB' },
          { name: 'Nephrectomy Main', caseId: 'NEP' },
          { name: 'Non Gynae Cytology', caseId: 'NGY' },
          { name: 'Ovarian Cancer', caseId: 'OVA' },
          { name: 'Pap Request', caseId: 'PAP' },
          { name: 'Prostate Cancer Biopsy', caseId: 'PCB' },
          { name: 'Prostate Cancer Radical Prostatectomy', caseId: 'PTMY' },
          { name: 'Prostate Turp & Enucleation', caseId: 'PTE' },
          { name: 'Renal Core & Incision Biopsies', caseId: 'RCIB' },
          { name: 'Soft Tissue Tumours', caseId: 'STTR' },
          { name: 'Leiomyomata', caseId: 'LEIO' },
          { name: 'Testicular Biopsy', caseId: 'TEB' },
          { name: 'Thyroidectomy', caseId: 'THCB' },
          { name: 'Wilm`s Tumor', caseId: 'WIL' },
        ];
        break;
      case 'Radiology':
        this.templates = [
          { name: 'PR Abdomen Pelvis', caseId: 'PRAP' },
          { name: 'PR Ankle Joint', caseId: 'PRANK' },
          { name: 'PR Ankle Joint Both', caseId: 'PRANKJ' },
          { name: 'PR Left Ankle Joint Report', caseId: 'LTAJ' },
          { name: 'PR Cervical Spine', caseId: 'CERS' },
          { name: 'PR Chest Report', caseId: 'PRC' },
          { name: 'PR Elbow Joint', caseId: 'PRELJ' },
          { name: 'PR Left / Right Joint', caseId: 'PRLRJ' },
          { name: 'PR Both Feet', caseId: 'PRBF' },
          { name: 'PR Feet Left', caseId: 'PRFL' },
          { name: 'PR Feet Right', caseId: 'PRFT' },
          { name: 'PR Both Hands', caseId: 'PRBH' },
          { name: 'PR Left Hand', caseId: 'PRHL' },
          { name: 'PR Right Hand', caseId: 'PRHR' },
          { name: 'PR Right Knee Joint Report', caseId: 'PRKJL' },
          { name: 'PR Left Knee Joint Report', caseId: 'LTKJ' },
          { name: 'PR Both Knee Joints Report', caseId: 'BKJ' },
          { name: 'PR Lumbar Spine', caseId: 'LUMB' },
          { name: 'PR Pelvis Report', caseId: 'PRP' },
          { name: 'PR Right Shoulder Joint Report', caseId: 'RSJ' },
          { name: 'PR Left Shoulder Joint Report', caseId: 'PRLSJ' },
          { name: 'PR Both Shoulder Joint', caseId: 'PRBSJ' },
          { name: 'PR Skull', caseId: 'PRSK' },
          { name: 'PR Thoracic Spine', caseId: 'PRTH' },
          { name: 'PR Both Wrist', caseId: 'PRBW' },
          { name: 'PR Left Wrist Report', caseId: 'LW' },
          { name: 'PR Right Wrist Report', caseId: 'PRWR' },
          { name: 'Us Abdomen Report', caseId: 'USAB' },
          { name: 'Us Abdomen and Pelvis', caseId: 'USAP' },
          { name: 'Us Abdomen and Pelvis Female', caseId: 'USAPF' },
          { name: 'Us-Anterior-Chest-Wall-Report', caseId: 'UACW' },
          { name: 'Us-Breast', caseId: 'USBRS' },
          { name: 'Us Chest Report', caseId: 'USC' },
          { name: 'Us-Gluteal', caseId: 'USGLR' },
          { name: 'Us-Right-Inguinal-Mass', caseId: 'USRIM' },
          { name: 'Neck & Doppler Report', caseId: 'UND' },
          { name: 'US Neck Report', caseId: 'USN' },
          { name: 'Us-Neonatal-Head-Report', caseId: 'UNH' },
          { name: 'US Obstetrics Anomaly', caseId: 'USRA' },
          { name: 'Us Obstetrics', caseId: 'ULSO' },
          { name: 'Us Obstetrics Late', caseId: 'USRL' },
          { name: 'Us Obstetrics Twin', caseId: 'USRT' },
          { name: 'Ut Paediatric Spine', caseId: 'USPS' },
          { name: 'Us Pelvis (Male)', caseId: 'USPM' },
          { name: 'Us Pelvis (Female)', caseId: 'USPF' },
          { name: 'Us Pelvis Transabdominal', caseId: 'USPTA' },
          { name: 'Us-Penile-Report', caseId: 'USP' },
          { name: 'Us-Posterior-Chest-Wall', caseId: 'USPCW' },
          { name: 'Us Renal Dopplers', caseId: 'USRDP' },
          { name: 'Us-Lt-Scrotum-Report', caseId: 'USS' },
          { name: 'Us Supraclavicular Report', caseId: 'USUP' },
          { name: 'Us-Left-Thigh-Mass-Report', caseId: 'USLTM' },
          { name: 'Us Transabdominal & Transvaginal', caseId: 'USPTV' },
          { name: 'Doppler US Both Lower Limb Veins', caseId: 'DUSBLLV' },
          { name: 'Doppler Us Both Lower Limb Arteries', caseId: 'DUSBLLA' },
          { name: 'Doppler Us Both Upper Limb Veins', caseId: 'DUSOBLV' },
          { name: 'Doppler Us Lt Lower Limb Veins', caseId: 'DUSOTLV' },
          { name: 'Doppler Us Lt Upper Limb Arteries', caseId: 'DUSULA' },
          { name: 'Doppler Us Lt Upper Limb Veins', caseId: 'DUSULV' },
          { name: 'Doppler Us Rt Lower Limb Veins', caseId: 'DUOTLLV' },
          { name: 'Doppler Us Rt Lower Limb Arteries', caseId: 'DUOTRLLA' },
          { name: 'Doppler Us Rt Upper Limb Veins', caseId: 'DUSRULV' },
          { name: 'General Template', caseId: 'GRS' },
          { name: 'CT Abdomen Pelvis Non Contrast', caseId: 'CAPN' },
          { name: 'CT Ankle', caseId: 'CTAE' },
          { name: 'CT Bone and Age Assessment', caseId: 'CTBA' },
          { name: 'CT Brain Sinus', caseId: 'CTBS' },
          { name: 'CT Brain Orbit', caseId: 'CTBO' },
          { name: 'CT Cervical Spine', caseId: 'CTCS' },
          { name: 'CT Chest', caseId: 'CTCH' },
          { name: 'CT Elbow', caseId: 'CTEW' },
          { name: 'Ct-scan-report', caseId: 'CT' },
          { name: 'CT Head (Contrast)', caseId: 'CTHD' },
          { name: 'CT Head Non Contrast', caseId: 'CTHN' },
          { name: 'CT Hip', caseId: 'CTHP' },
          { name: 'CT Intravenous Urography', caseId: 'CTIU' },
          { name: 'CT Knee', caseId: 'CTKN' },
          { name: 'Ct Lumbar Spine', caseId: 'CTLS' },
          { name: 'CT Neck', caseId: 'CTNK' },
          { name: 'CT Pelvis', caseId: 'CTPEL' },
          { name: 'CT Peripheral Angiography', caseId: 'CTPEA' },
          { name: 'CT Pulmonary Angiography', caseId: 'CTPA' },
          { name: 'CT Shoulder', caseId: 'CTSR' },
          { name: 'CT Thoracic Spine', caseId: 'CTTS' },
          { name: 'Ct Triple Phase Abdomen', caseId: 'CTTPA' },
          { name: 'Ecg Template', caseId: 'ECGT' },
          { name: 'Echocardiographic-Report', caseId: 'ECHO' },
          { name: 'Echocardiography Report', caseId: 'ELG' },
          { name: 'Mammogram Both Breast', caseId: 'MGBB' },
          { name: 'Mammogram Left Breast', caseId: 'MGLB' },
          { name: 'Mammogram Right Breast', caseId: 'MGRB' },
          { name: 'Hsg Template', caseId: 'HSGN' },
          { name: 'Barium Enema', caseId: 'BMEA' },
          { name: 'Barium Meal', caseId: 'BMML' },
          { name: 'Barium Swallow', caseId: 'BMSW' },
        ];
        break;
      case 'Clinical Laboratory':
        this.templates = [
          { name: 'Combined Haematology Template', caseId: 'CHT' },
          { name: 'Custom Lab Template', caseId: 'CLT' },
          { name: 'Coagulation', caseId: 'COAG' },
          { name: 'Chemical Pathology', caseId: 'GLT' },
          { name: 'Electrocardiogram Report', caseId: 'ECG' },
          { name: 'Electrolytes', caseId: 'ELTL' },
          { name: 'General DNA Templates', caseId: 'DNA' },
          { name: 'Hormones Analysis', caseId: 'HANS' },
          { name: 'Lipid Profile Test', caseId: 'LPDP' },
          { name: 'Lower GI Endoscopy Report', caseId: 'LGE' },
          { name: 'Microbiology', caseId: 'MBT' },
          { name: 'Parasitology', caseId: 'PARA' },
          { name: 'Serology Template', caseId: 'SGT' },
          { name: 'Upper GI Endoscopy Report', caseId: 'UGE' },
          { name: 'Urinalysis', caseId: 'URNS' },
          { name: 'Spirometry Report', caseId: 'SPIRO' },
        ];
        break;
      case 'General Practice':
        this.templates = [
          { name: 'Admissions', caseId: 'ADM' },
          { name: 'Consultation', caseId: 'CA' },
          { name: 'Physical Examination', caseId: 'PE' },
        ];
        break;
      default:
        this.templates = [];
    }
  }


  exportData() {
    this.spinner.show(); // Show spinner when starting data export
    setTimeout(() => { this.spinner.hide(); }, 1000); // Hide spinner after 1 second
    if (!this.selectedCaseId) {
      console.error('Please select a case ID');
      return; // Stop execution if no case ID is selected
    }

    const requestData = {
      case_id: this.selectedCaseId,
      start_date: this.fromDate,
      end_date: this.toDate
    };

    const queryString = new URLSearchParams(requestData as any).toString();

    this.auth.get(`report_export?${queryString}`).subscribe((response: any) => {
      if (response && Array.isArray(response.data)) {
        this.data = response.data; // Assign data properly

        // Extract headers dynamically if not provided
        this.headers = response.headers || (this.data.length > 0 ? Object.keys(this.data[0]) : []);

        console.log('Exported data:', this.data);
        console.log('Headers:', this.headers);
      } else {
        this.data = [];
        this.headers = [];
        console.warn('No valid data received.');
      }
    }, error => {
      console.error('Error exporting data:', error);
    });
  }





  goBack(): void {
    window.history.back();
  }





  // Method to convert data to CSV format
  convertToCSV(data: any[]) {
    const headers = this.headers.join(',');
    const rows = data.map(row => row.join(','));
    return [headers, ...rows].join('\n');
  }

  // Method to download data as XLSX
  downloadXLSX() {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Save file
    XLSX.writeFile(wb, 'exported-data.xlsx');
  }


  // Clear data
  clearData() {
    this.data = [];
    this.headers = [];
    this.selectedCaseId = '';
    this.fromDate = '';
    this.toDate = '';

  }


  downloadData(format: string) {
    if (!this.data || this.data.length === 0) {
      console.warn('No data available to download.');
      return;
    }

    if (format === 'csv') {
      this.downloadCSV();
    } else if (format === 'xlsx') {
      this.downloadXLSX();
    }
  }

  downloadCSV() {
    if (!this.headers || this.headers.length === 0) {
      console.error('No headers found. Cannot generate CSV.');
      return;
    }

    // Convert data to CSV format
    let csvContent = this.headers.join(',') + '\n'; // Add headers

    this.data.forEach(row => {
      let rowData = this.headers.map(header => row[header] ?? '').join(',');
      csvContent += rowData + '\n';
    });

    // Download CSV
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'exported_data.csv';
    a.click();
    window.URL.revokeObjectURL(url);
  }

  previewData() {
    if (!this.data || this.data.length === 0) {
      console.warn('No data available to preview.');
      return;
    }

    // Open a new tab
    const newTab = window.open('', '_blank');
    if (!newTab) {
      alert('Popup blocked! Please allow pop-ups for this site.');
      return;
    }

    // Generate table HTML as a string
    const tableHTML = `
    <html>
    <head>
      <title>Data Preview</title>
      <style>
        body { font-family: Arial, sans-serif; padding: 20px; }
        table { border-collapse: collapse; width: 100%; }
        th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
        th { background-color: #f2f2f2; }
        .container { max-width: 95vw; overflow: auto; }
      </style>
    </head>
    <body>
      <h2>Data Preview</h2>
      <div class="container">
        <table>
          <thead>
            <tr>${this.headers.map(header => `<th>${header}</th>`).join('')}</tr>
          </thead>
          <tbody>
            ${this.data.map(row => `<tr>${this.headers.map(header => `<td>${row[header] ?? ''}</td>`).join('')}</tr>`).join('')}
          </tbody>
        </table>
      </div>
    </body>
    </html>
  `;

    // Write the HTML content to the new tab safely
    newTab.document.open();
    newTab.document.write(tableHTML);
    newTab.document.close();
  }

}

